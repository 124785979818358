import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import './User.css';
import { FC, useState } from 'react';
import { useRef } from 'react';
import axis from 'axios';
import { Oval } from 'react-loader-spinner'


const User: FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [imgNo, setImgNo] = useState('1');
  const [name, setName] = useState('未設定');
  const [oldName, setOldName] = useState('未設定');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState<string | undefined>()

  const { state } = useLocation();
  const uid = state.uid;
  const organizationId = state.organizationId;;
  const os = state.os;

  React.useLayoutEffect(() => {
    if (state != null){
      setImgNo(state.icon);
      setName(state.name);
      setOldName(state.name);
      setToken(state.token);
    }
  }, []);

  const navigate = useNavigate()
  const handleMenu = () => {
      navigate('/?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
          state: {
            viewState: true,
            name: name,
            icon: imgNo,
            token: token
          }
      })
  }

  const imgUrl = './images/icon/'+imgNo+'.png'



  const usersAdd = () => {
    // // event.preventDefault();
    setIsLoggedIn(true);

    axis.post('https://location-sharing-api-stg.ap.litcity.ne.jp/users/', {
      uid: uid,
      organization_id: organizationId,
      name: name,
      token: token,
      icon: imgNo,
      os: os
    })
    .then(red => {
      console.log(red.data);
      setIsLoggedIn(false);
      setMessage('保存しました')
      handleShowResultModal()
    })
    .catch((error) => {
      setIsLoggedIn(false);
      setName(oldName);
      setMessage('保存に失敗しました')
      handleShowResultModal()
    });
  }

  const dialogRef = useRef<HTMLDialogElement>(null);
  const handleShowModal = () => dialogRef.current?.showModal();
  const handleCloseModal1 = () => {
    setImgNo("1");
    dialogRef.current?.close();
  }
  const handleCloseModal2 = () => {
    setImgNo("2");
    dialogRef.current?.close();
  }
  const handleCloseModal3 = () => {
    setImgNo("3");
    dialogRef.current?.close();
  }
  const handleCloseModal4 = () => {
    setImgNo("4");
    dialogRef.current?.close();
  }

  const dialogResultRef = useRef<HTMLDialogElement>(null);
  const handleShowResultModal = () => dialogResultRef.current?.showModal();
  const handleCloseResultModal = () => {
    dialogResultRef.current?.close()
    if (message === '保存しました'){
      handleMenu()
    }
  }

  return (
      <div className="User">
        <header className="User-header">
          <div className="User-header-back" onClick={handleMenu}>
            <span className="dli-chevron-round-left"></span>
          </div>
          <div className="User-header-title font-navi-title">
            プロフィール編集
          </div>
        </header>
        {isLoggedIn ? (
          <div className="User-cover">
            <div className="User-cover-oval">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <body className="User-body">
          <div className="User-logo">
            <img src={imgUrl} alt="logo" onClick={handleShowModal}/>
          </div>
          <div className="User-title font-contents-title">ニックネーム</div>
          <input className="User-from-text font-contents-body" value={name} defaultValue={name} type="text" onChange={(event) => setName(event.target.value)} />
          <div className="User-from-send font-button" onClick={usersAdd}>保存する</div>
          <dialog className="User-dialog" ref={dialogRef}>
            <div className="User-dialog-title font-contents-body">アイコンを選択してください。</div>
            <div className="User-dialog-contents">
              <img className="User-dialog-icon" src='./images/icon/1.png' alt="logo" onClick={handleCloseModal1}/>
              <img className="User-dialog-icon" src='./images/icon/2.png' alt="logo" onClick={handleCloseModal2}/>
              <img className="User-dialog-icon" src='./images/icon/3.png' alt="logo" onClick={handleCloseModal3}/>
              <img className="User-dialog-icon" src='./images/icon/4.png' alt="logo" onClick={handleCloseModal4}/>
            </div>
          </dialog>
          <dialog className="User-dialog" ref={dialogResultRef}>
            <div className="User-dialog-title font-contents-body">{message}</div>
            <div className="User-button" onClick={handleCloseResultModal}>
              OK
            </div>
          </dialog>
        </body>
    </div>
  );
};
export default User;


