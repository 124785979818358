import { useNavigate } from "react-router-dom";
import './Member.css';
import React from "react";
import { useLocation } from "react-router-dom";
import { useRef, useState, useEffect, FC } from 'react';
import axis from 'axios';
import { Bars } from 'react-loader-spinner'

type member ={
    icon: string;
    name: string;
    uid: string;
}

const Member: FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [deleteMemberUid, setDeleteMemberUid] = useState("");
    const { state } = useLocation();
    const navigate = useNavigate()
    const handleMenu = () => {
        navigate('/?organization_id='+state.organizationId+'&uid='+state.uid+'&os='+state.os, {
            state: {
                viewState: true,
                name: state.name,
                icon: state.icon,
                token: state.token
            }
        })
    }
    const [memberList, setMemberList] = useState<member[]>([{icon: "", name: "現在登録されているメンバーがいません。", uid: ""}]);

    React.useLayoutEffect(() => {
        // useEffect(() => {
            getMemberList()
        // },);
    }, []);

    const dialogRef = useRef<HTMLDialogElement>(null);
    const handleShowModal = (getDeleteMemberUid: string) => {
        dialogRef.current?.showModal();
        console.log(getDeleteMemberUid)
        setDeleteMemberUid(getDeleteMemberUid)
    }
    const handleCloseModal = () => {
        dialogRef.current?.close();
        setDeleteMemberUid("")
    }
    const handleDeleteCloseModal = () => {
        dialogRef.current?.close();
        deleteMember()
    }

    const deleteMember = () => {
        setIsLoggedIn(true)
        console.log(state.uid)
        console.log(state.organizationId)
        console.log(deleteMemberUid)
        axis.post('https://location-sharing-api-stg.ap.litcity.ne.jp/regist/delete', {
            uid: state.uid,
            organization_id: state.organizationId,
            member_uid: deleteMemberUid
        })
        .then(red => {
            setMemberList([{icon: "", name: "現在登録されているメンバーがいません。", uid: ""}])
            getMemberList()
            setDeleteMemberUid("")
        })
        .catch((error) => {
            setIsLoggedIn(false)
            setDeleteMemberUid("")
        });
    }

    const getMemberList = () => {
        const uid = state.uid
        const organizationId = state.organizationId
        const getMemberUrl = 'https://location-sharing-api-stg.ap.litcity.ne.jp/members/?organization_id='+organizationId+'&uid='+uid;
        axis.get(getMemberUrl).then((response) => {
            // setMemberList(getMemberList);
            const data = response.data;
            console.log(data);
            const memberUids = data.member_uids;
            const getMemberList: member[]= []
            var arrayCount = memberUids.length;
            if (arrayCount == 0) {
                setIsLoggedIn(false);
            }
            memberUids.forEach((memberUid: string) => {
                const getUserUrl = 'https://location-sharing-api-stg.ap.litcity.ne.jp/users/?organization_id='+organizationId+'&uid='+memberUid+'&puid=puid';
                axis.get(getUserUrl).then((userResponse) => {
                    const userData = userResponse.data;
                    getMemberList.push({
                        icon: './images/icon/'+userData.icon+'.png',
                        name: userData.name,
                        uid: userData.uid
                    })
                    arrayCount--;
                    console.log(arrayCount);
                    if (arrayCount == 0) {
                        setMemberList(getMemberList)
                        setIsLoggedIn(false)
                    }
                })
                .catch(error => {
                    arrayCount--;
                    if (arrayCount == 0) {
                        setIsLoggedIn(false)
                    }
                });
            });
            console.log(memberList)
        })
        .catch(error => {
            setIsLoggedIn(false)
        })
    }

    return (
        <div className="Member">
        <header className="Member-header">
            <div className="Member-header-back" onClick={handleMenu}>
                <span className="dli-chevron-round-left"></span>
            </div>
            <div className="Member-header-title font-navi-title">
                登録者一覧
            </div>
        </header>
        {isLoggedIn ? (
            <div className="Member-cover">
                <div className="Member-cover-oval">
                    <Bars
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </div>
        ) : (
            ""
        )}
        <body className="Member-body">
            {memberList.map((member) => (
                <div className="Member-item">
                    {member.icon == "" ? (
                        <div> </div>
                    ) : (
                        <div className="Member-icon">
                            <img src={member.icon} alt="icon" />
                        </div>
                    )}
                    {member.icon == "" ? (
                        <div className="Member-name font-contents-sub-body">{member.name}</div>
                    ) : (
                        <div className="Member-name font-contents-body">{member.name}</div>
                    )}
                    {member.icon == "" ? (
                        <div> </div>
                    ) : (
                        <div className="delete-btn" onClick={() => handleShowModal(member.uid)}>
                            <span className="round_btn"></span>
                        </div>
                    )}
                </div>
            ))}
            <dialog className="Member-dialog" ref={dialogRef}>
                <div className="Member-dialog-title font-contents-body">登録中のメンバーから削除しますか？</div>
                <div className="Member-button font-button" onClick={handleDeleteCloseModal}>OK</div>
                <div className="Member-button font-button" onClick={handleCloseModal}>キャンセル</div>
            </dialog>
        </body>
    </div>
    );
};
export default Member;


