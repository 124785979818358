import { useNavigate } from "react-router-dom";
import './Register.css';
import { QRCodeCanvas } from 'qrcode.react';
import { FC, useState} from "react";
import { useLocation } from "react-router-dom";
import React from "react";

const Register: FC = () => {
    const { state } = useLocation()

    const [isAppShow, setIsAppShow] = useState(false);
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    var uid = "none"
    var organizationId = "none"
    var os = "none"
    var name = "none"
    var icon = "none"
    var token = "none"

    if (query.has('show')) {
        uid = query.get('uid') || "none"
        organizationId = query.get('organization_id') || "none"
        os = query.get('os') || "none"
        name = query.get('show') || "none"
    }else {
        uid = state.uid
        organizationId = state.organizationId
        os = state.os
        name = state.name
        icon = state.icon
        token = state.token
    }

    const navigate = useNavigate()
    const handleMenu = () => {
        navigate('/?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
            state: {
                viewState: true,
                name: name,
                icon: icon,
                token: token
            }
        })
    }
    const handleQrcode = () => {
        navigate('/Qrcode?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
            state: {
                name: name,
                icon: icon,
                uid: uid,
                organizationId: organizationId,
                os: os,
                token: token,
                isAppShow: isAppShow
            }
        })
    }

    const qrUrl = 'member_uid='+uid;
    const handleShare = () => {
        const canvas = document.querySelector('canvas');
        if (canvas) {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'qrcode.png'; // ダウンロードする画像のファイル名
            link.click();
        }
    };

    React.useLayoutEffect(() => {
        if (query.has('show')) {
            setIsAppShow(true)
        }else {
            setIsAppShow(state.isAppShow || false)
        }
    }, []);

    return (
        <div className="Register">
        <header className="Register-header">
        {isAppShow ? (
            ""
        ): (
            <div className="History-header-back" onClick={handleMenu}>
                <span className="dli-chevron-round-left"></span>
            </div>
        )}
            <div className="Register-header-title font-navi-title">
                通知メンバー登録
            </div>
        </header>
        <body className="Register-body">
            <div className="Register-explanation-title font-contents-sub-body Register-explanation-text">
                例）お子さんの通知を受け取りたい場合、お子さんのアプリで「QRコードリーダー」を起動して、保護者のQRコードを読み取ってください。
            </div>
            <div className="Register-explanation ">
                <div className="Register-explanation-title font-contents-sub-title">受け取る</div>
                <div className="Register-title font-contents-sub-body">
                    <div>あなたのQRコード</div>
                    <div>※アプリから読み取ってください。</div>
                </div>
                <div className="Register-qrcode">
                    <QRCodeCanvas
                        value={qrUrl}
                        size={280}
                        level="H"
                        includeMargin={true}
                        />
                </div>
                <div className="Register-button font-button" onClick={handleShare}>
                    QRコードをダウンロード
                </div>
            </div>
            <div className="Register-explanation ">
                <div className="Register-explanation-title font-contents-sub-title">送る</div>
                <div className="Register-button font-button" onClick={handleQrcode}>
                    QRコードリーダーを起動
                </div>
            </div>
            <div className="Register-explanation ">
                <div className="Register-explanation-title font-contents-sub-title">ボタン説明</div>
                <div className="Register-explanation-body-title Register-explanation-button font-explanation-button">QRコードリーダーを起動</div>
                <div className="Register-explanation-body-text font-contents-sub-body">通知を送信したい相手のQRコードを読み取ってください。</div>
                <div className="Register-explanation-body-title Register-explanation-button font-explanation-button">QRコードをダウンロード</div>
                <div className="Register-explanation-body-text font-contents-sub-body">QRコードの画像をダウンロードします。「QRコードリーダー」の画面でダウンロードしたQRコード画像をアップロードできます。</div>
            </div>
        </body>
    </div>
    );
};
export default Register;


