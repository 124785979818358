import { Routes, Route } from "react-router-dom";
import Menu from "./components/Menu";
import User from "./components/User";
import Register from "./components/Register";
import Member from "./components/Member";
import History from "./components/History";
import Detail from "./components/Detail";
import Qrcode from "./components/Qrcode";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/user" element={<User />} />
            <Route path="/register" element={<Register />} />
            <Route path="/member" element={<Member />} />
            <Route path="/history" element={<History />} />
            <Route path="/detail" element={<Detail />} />
            <Route path="/qrcode" element={<Qrcode />} />
        </Routes>
    )
}